<template>
	<div class="invoices">
		<FilterDrawer :allInvoices="allInvoices" />
		<a-card class="mt-3" :title="null">
			<div class="dF aC jSB">
				<div class="dF aC">
					<h5 class="text-dark">Summary</h5>
					<div class="ml-5" style="line-height: 27px; color: #9ea0a5">
						{{ `${invoices.length} total` }}
					</div>
				</div>
				<div class="dF aC" style="gap: 30px">
					<div>
						<a-input placeholder="Search your projects..." v-model="search" size="large" allowClear>
							<a-tooltip slot="prefix" title="Search your projects" overlayClassName="change-tooltip-color">
								<a-icon type="search" style="color: black" />
							</a-tooltip>
						</a-input>
					</div>
					<div @click="$store.commit('OPEN_FILTER')" class="dF aC px-3 py-2 ml-3"
						style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
						<div class="mr-2">Filters</div>
						<i class="fe fe-filter" />
					</div>
				</div>
			</div>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<a-table :rowKey="(e, i) => i" :columns="columns" :dataSource="invoices" :pagination="{ pageSize: 20 }"
				:scroll="{ x: 1200 }" class="white-table">
				<div slot="readableId" slot-scope="invoice" class="text-dark" style="font-size: 15px;">
					{{
						invoice &&
						invoice.instance &&
						invoice.instance.readableId || ''
					}}
				</div>
				<div slot="instance" slot-scope="invoice" class="text-dark" style="font-size: 15px; min-width: 100px">
					{{
						(invoice && invoice.instance && invoice.instance.name)
					}}
				</div>
				<div slot="dueDate" slot-scope="invoice" style="color: #9ea0a5">
					{{ !invoice.validUntil ? "N/A" : getDate(+invoice.validUntil) }}
				</div>
				<div slot="invoiceDate" slot-scope="invoice" style="color: #9ea0a5; min-width: 150px">
					{{
						!invoice.fromDate
						? "N/A"
						: invoice.fromDate
					}}
				</div>
				<div slot="invoiceNumber" slot-scope="invoice">
					{{
						!invoice
						? "N/A"
						: invoice.invoiceNumber
					}}
				</div>
				<div slot="projectStatus" slot-scope="invoice" class="text-center">
					<div v-if="invoice && invoice.instance">
						<span v-if="invoice.instance.deleted">Deleted</span>
						<span v-else-if="invoice.instance.susspended"> Locked </span>
						<span v-else> Active</span>
					</div>
					<div v-else>
						Active
					</div>
				</div>
				<div slot="total" slot-scope="invoice" class="text-dark" style="font-size: 15px">
					{{
						!invoice ? "$0" : `$${invoice.total}`
					}}
					({{ invoice.currency || 'USD' }})
				</div>
				<div slot="transaction" slot-scope="invoice">
					<div class="dF aC" style="gap: 10px" v-if="invoice.paymentMode !== 'offline'">
						<img alt="no-card" :src="getTransactionDetails(invoice).imageUrl" width="40%" height="40%" />
						{{ getTransactionDetails(invoice).number }}
					</div>
				</div>
				<div slot="status" slot-scope="invoice">
					<span v-if="invoice" class="py-1 px-2 paid-button" :style="invoice.paid
							? 'background-color:#28C791'
							: 'background-color:#FD647C'
						">
						{{ invoice.paid ? "PAID" : "UNPAID" }}
					</span>
					<span v-else>N/A</span>
				</div>
				<div slot="refundTotal" slot-scope="invoice">
					<span v-if="invoice.refunds">
						{{ calculateTotalRefund(invoice.refunds) }} ({{
							invoice.currency || "USD"
						}})
					</span>
				</div>
				<div slot="refundStatus" slot-scope="invoice">
					<span class="paid-button py-1 px-2" :style="invoice.refundStatus
								? invoice.refundStatus === 'partial'
									? 'background-color:#ffc107'
									: 'background-color: var(--primary)'
								: ''
							">
						<a-tooltip overlayClassName="change-tooltip-color">
	                        <template slot="title" v-if="invoice.refunds && invoice.refunds.length">
	                            <div v-for="r in invoice.refunds" :key="r.id">
									<p> {{r.reason || 'N/A'}}: ${{r.amount}}({{invoice.currency || "USD"}}) </p>
								</div>
	                        </template>
	                        {{
                                invoice.refundStatus
                                    ? invoice.refundStatus === "partial"
                                        ? "PARTIAL"
                                        : "FULL"
                                    : ""
                            }}
	                    </a-tooltip>
					</span>
				</div>
				<div slot="operation" slot-scope="invoice">
					<div v-if="invoice" class="dF aC jSB">
						<div @click="$emit('viewInvoice', invoice)" class="py-1 px-2 text-center" style="font-size: 14px background-color: #fff; color: black; border: 1px solid var(--dark); border-radius: 4px; cursor: pointer;">
							VIEW INVOICE
						</div>
						<div @click="() => {
							if (!invoice.paid && invoice.paymentMode !== 'offline') {
								payAgain(invoice.id)
							}
						}" class="py-1 px-2 text-center" :style="!invoice.paid && invoice.paymentMode !== 'offline'
		? 'background-color:var(--orange); color:#FFF; cursor:pointer'
		: 'background-color:var(--light-gray); color:var(--med-gray); cursor:not-allowed'
	" style="font-size: 14px; border-radius: 4px">
							PAY
						</div>
						<a-button @click="printInvoice(invoice, false);" type="link" style="font-size: 16px; padding: 0px"
							icon="download" size="small">
							PDF
						</a-button>
                        <a-button @click="printInvoice(invoice, true);" type="link" style="font-size: 16px; padding: 0px"
							icon="eye" size="small">
							PDF
						</a-button>
					</div>
				</div>
			</a-table>
		</a-card>

		<div>
			<InvoicePDF :invoice="invoiceToDownload" :userPaymentMethods="userPaymentMethods"
				@close="invoiceToDownload = null; previewModal = false;" :previewModal="previewModal" />
		</div>
	</div>
</template>

<script>
import InvoicePDF from "@/components/common/InvoicePDF";
import FilterDrawer from '@/components/common/FilterDrawer'
import moment from "moment";

export default {
	components: {
		InvoicePDF,
		FilterDrawer
	},

	props: {
		allInvoices: {
			type: Array,
			default: () => [],
		},
		userPaymentMethods: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			invoiceToDownload: null,
            previewModal: false,
			search: '',
		};
	},

	computed: {
		columns() {
			return [
				{
					title: "Project Id",
					key: "readableId",
					scopedSlots: { customRender: "readableId" },
					sorter: (a, b) => {
						const aa = (a && a.instance && a.instance.readableId || '');
						const bb = (b && b.instance && b.instance.readableId || '');
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					},
					width: "7%"
				},
				{
					title: "Project Name",
					key: "instance",
					scopedSlots: { customRender: "instance" },
					sorter: (a, b) => {
						const aa = (a && a.instance && a.instance.name || '');
						const bb = (b && b.instance && b.instance.name || '');
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					},
				},
				{
					title: "Invoice #",
					key: "invoiceNumber",
					scopedSlots: { customRender: "invoiceNumber" },
					sorter: (a, b) =>
						(a && a.invoiceNumber || 0) -
						(b && b.invoiceNumber || 0),
					width: "7%"
				},
				{
					title: "Invoice Date",
					key: "invoiceDate",
					scopedSlots: { customRender: "invoiceDate" },
					sorter: (a, b) =>
						(a && a.fromDate &&
							moment(a.fromDate, "DD/MM/YYYY").format(
								"x"
							) ||
							0) -
						(b && b.fromDate &&
							moment(b.fromDate, "DD/MM/YYYY").format(
								"x"
							) ||
							0),
					width: "7%"
				},
				{
					title: "Next Invoice Date",
					key: "dueDate",
					scopedSlots: { customRender: "dueDate" },
					sorter: (a, b) =>
						(a && a.validUntil &&
							moment(a.validUntil, "DD/MM/YYYY").format(
								"x"
							) ||
							0) -
						(b && b.validUntil &&
							moment(b.validUntil, "DD/MM/YYYY").format(
								"x"
							) ||
							0),
					width: "7%"
				},
				{
					title: "Amount",
					key: "total",
					scopedSlots: { customRender: "total" },
					sorter: (a, b) =>
						(a && a.total || 0) -
						(b && b.total || 0),
					width: "10%"
				},
				{
					title: "Transaction",
					key: "transaction",
					scopedSlots: { customRender: "transaction" },
					width: "10%"
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
					sorter: (a, b) => {
						const aa =
							(a && a.paid ? "PAID" : "UNPAID") ||
							"";
						const bb =
							(b && b.paid ? "PAID" : "UNPAID") ||
							"";
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					},
					width: "7%"
				},
				{
					title: "Refund Status",
					key: "refundStatus",
					scopedSlots: { customRender: "refundStatus" },
					sorter: (a, b) => {
						const aa = a.refundStatus || "";
						const bb = b.refundStatus || "";
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					},
					width: "7%"
				},
				{
					title: "Project Status",
					key: "projectStatus",
					scopedSlots: { customRender: "projectStatus" },
					sorter: (a, b) =>
						((a &&
							a.instance &&
							a.instance.susspended) ||
							-1) -
						((b &&
							a.instance &&
							a.instance.susspended) ||
							1),
					width: "7%"
				},
				{
					title: "",
					key: "operation",
					scopedSlots: { customRender: "operation" },
					width: "20%"
				},
			];
		},

		invoices() {
			let result = this.allInvoices.filter(invoice => invoice.instance);

			let filter = this.$store.state.filterDrawer.filter;
			if (this.search) {
				result = result.filter(invoice => {
					const instanceName = (invoice && invoice.instance && invoice.instance.name) || '';
					const instanceId = (invoice && invoice.instance && invoice.instance.readableId) || '';
					return instanceName.toLowerCase().includes(this.search.toLowerCase()) || instanceId.toLowerCase().includes(this.search.toLowerCase())
				})
			}
			if (!filter.showDelete) {
				result = result.filter(invoice => !invoice || !invoice.instance || !invoice.instance.deleted)
			}

			if (filter.showLatestOnly) {
				const instances = {};
				result.forEach(invoice => {
					const instanceID = invoice.instance && invoice.instance.id
					if (!instances[instanceID]) {
						instances[instanceID] = invoice
					} else if (moment(instances[instanceID].updatedAt).format('x') <= moment(invoice.updatedAt).format('x')) {
						instances[instanceID] = invoice
					}
				});
				result = Object.values(instances)
			}

			if (filter.price && (filter.price[0] !== 0 || filter.price[1] !== 0)) {
				result = result.filter(invoice => invoice.total >= filter.price[0] && invoice.total <= filter.price[1])
			}

			if (filter.productType[0] !== "any" && filter.productType.length == 1) {
				result = result.filter(invoice => invoice.instance && invoice.instance.productType === filter.productType[0])
			}

			if (filter.status[0] !== "any" && filter.status.length == 1) {
				result = result.filter(invoice => filter.status[0] === 'paid' ? invoice.paid : !invoice.paid)
			}

			if (filter.timeFrame[0] !== null) {
				const startDate = +moment(filter.timeFrame[0]).startOf("day").format("x")
				result = result.filter(invoice => invoice.fromDate && startDate <= (+moment(invoice.fromDate, "DD/MM/YYYY").format("x")))
			}

			if (filter.timeFrame[1] !== null) {
				const endDate = +moment(filter.timeFrame[1]).startOf("day").format("x")
				result = result.filter(invoice => invoice.validUntil && endDate >= invoice.validUntil)
			}

			return result.sort((a, b) => moment(b.updatedAt).format('x') - moment(a.updatedAt).format('x'))
		}
	},

	methods: {
		payAgain(id) {
			this.$message.info("Payment initiated.");
			this.$api
				.get(`/invoices/tryAgain/${id}`)
				.then(({ data }) => {
					if (data && data.success) {
						this.$message.success("Payment done successfully.");
						let index = this.allInvoices.findIndex(
							(x) => x.invoice.id == data.id
						);
						if (index != -1) {
							this.allInvoices[index].invoice.paid = data.paid;
						}
					} else {
						this.$message.error(`Payment failed. ${data && data.message && data.message.message || ''}`);
					}

				})
				.catch((err) => {
					console.error(
						"Error while making payment for the invoice",
						err
					);
				});
		},

		getDate(date) {
			return moment(date).format("DD/M/YYYY");
		},

		calculateTotalRefund(refunds) {
			const total = refunds
				.reduce((a, b) => {
					return a + b.amount;
				}, 0)
				.toFixed(2);
			return total ? "$" + total : "";
		},

		getTransactionDetails(invoice) {
			if (invoice && invoice.instance && invoice.instance.paymentMethod) {
				const payment = invoice.instance.paymentMethod;
				return {
					imageUrl: payment.details.ccBrand
						? require(`@/assets/${payment.details.ccBrand}.svg`)
						: "N/A",
					number: payment.details.cardNo
						? "ending " + payment.details.cardNo.slice(-4)
						: "N/A",
				};
			} else {
				return {
					imageUrl: "N/A",
					number: "N/A",
				};
			}
		},

		printInvoice(invoice, previewModal = false) {
			let selectedInvoice = JSON.parse(JSON.stringify(invoice));
			if (selectedInvoice.details && selectedInvoice.details.featureBlocks && selectedInvoice.details.featureBlocks.length) {
				if (!selectedInvoice.details.selectedPackageExtras) {
					selectedInvoice.details.selectedPackageExtras = []
				}
				selectedInvoice.details.featureBlocks.forEach(
					(x) => {
						if (x.name === "Training hours") {
							const index = selectedInvoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
							if (index >= 0) {
								selectedInvoice.details.selectedPackageExtras[index] = {
									...x,
									name: "Training Hours",
									quantity: x.quantity,
								}
							} else {
								selectedInvoice.details.selectedPackageExtras.push(
									{
										...x,
										name: "Training Hours",
										quantity: x.quantity,
									}
								);
							}
						} else {
							selectedInvoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
						}
					}
				);
			}

            this.previewModal = previewModal
			this.invoiceToDownload = selectedInvoice
		},
	},
};
</script>

<style lang="scss" scoped>
.paid-button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 2px 8px;
}
</style>

<style lang="scss">
.invoices .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
	padding: 10px
}

.invoices .ant-card-body {
	padding: 12px
}
</style>
